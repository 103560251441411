<template>
  <div v-if="vault_wishes[q.key]">
    <span v-if="!q.options">
      {{ vault_wishes[q.key] }}
    </span>
    <span v-if="q.options && vault_wishes[q.key]">
      {{ this.getOptionValue(q) }}
    </span>
    <div class="mt-2">
      <slot />
    </div>
  </div>
  <div class="text-no-result pa-0 mb-2 text-left" v-else-if="!q.related">No answer provided</div>
</template>
<script>
export default {
  props: ['q', 'vault_wishes'],
  methods: {
    getOptionValue(q) {
      const v = q.options.find((x) => (typeof x === 'object' ? x.key : x) === this.vault_wishes[q.key])
      return typeof v === 'object' ? v.label : v
    }
  }
}
</script>

<template>
  <div>
    <div v-if="local">
      <div class="mb-4">
        <h2 class="mb-4 line-height-md">
          What do you want done with your remains?
        </h2>
        <notification-box
          :flat="true"
          type="info"
          class="mb-4"
        >
          <p>
            <strong>Note:</strong> Changing this answer will also update your
            online Will. If you change this answer you should print and sign
            your Will again.
          </p>
        </notification-box>
        <item-select
          v-for="item in options"
          :key="item.key"
          :value="item.key === localFormatted.remains"
          :title="item.label"
          class="mb-2"
          @select="local.remains = item.key"
        />
        <v-input
          :error-messages="
            showErrors.remains && errorMessages.remains
              ? errorMessages.remains
              : null
          "
        />
      </div>
      <div class="d-flex align-center">
        <v-btn
          v-bind="backButton"
          @click="close"
          ><v-icon left>mdi-chevron-left</v-icon>{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Save</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, isEqual } from "lodash"
import { mapGetters } from "vuex"

import ItemSelect from "../../../components/ui/ItemSelect"
import NotificationBox from "../../../components/ui/NotificationBox"

export default {
  name: "MemorialRemainsForm",
  components: { NotificationBox, ItemSelect },
  props: {
    closeLabel: {
      default: "Close",
    },
    submitLabel: {
      default: "Save",
    },
    onSaveBehaviour: {
      default: "close", //close | next
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      remains: false,
    },
    processing: false,
    allowFamilyOption: false,
  }),
  mounted() {
    this.local = cloneDeep(this.will_funeral_wishes.value)
    if (this.local.remains === "family") {
      this.allowFamilyOption = true
    }
  },
  methods: {
    async submit() {
      this.processing = true
      if (this.isValid) {
        if (this.isChanged) {
          await this.save()
          await this.cleanData()
        }
        this.showErrorsSwitch()

        setTimeout(() => {
          this.processing = false

          if (this.onSaveBehaviour === "close") {
            this.close()
          }
          if (this.onSaveBehaviour === "next") {
            this.$emit("next")
          }
        }, 500)
        return
      }
    },
    async save() {
      await this.$store.dispatch(
        "account/save_will_funeral_wishes",
        this.localFormatted
      )
      await this.$store.dispatch("account/update_will_progress", ["wishes"])
    },
    close() {
      this.$emit("close")
    },
    async cleanData() {
      const questions = this.$store.getters["account/list_group"](
        "memorialisation"
      ).filter(
        (x) =>
          x.remains &&
          !x.remains.includes(this.will_funeral_wishes.value.remains)
      )

      const clearance = questions.reduce(
        (obj, q) => ({
          ...obj,
          [q.key]: undefined,
        }),
        {}
      )

      await this.$store.dispatch("account/save_vault", {
        id: "wishes",
        data: { memorialisation: clearance },
      })
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
    validRemainSelection(key) {
      const keys = this.options.map((option) => {
        return option.key
      })

      return keys.includes(key)
    },
  },
  computed: {
    calcSubmitLabel() {
      return this.localFormatted.remains === "family"
        ? "Save"
        : this.submitLabel
    },
    ...mapGetters("account", ["will_funeral_wishes"]),
    options() {
      return this.$store.getters["account/list_group"]("remains").filter(
        (o) => {
          return o.key !== "family" || this.allowFamilyOption
        }
      )
    },
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        remains: null,
      }

      if (!this.localFormatted.remains) {
        msgs.remains = "Required field"
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.will_funeral_wishes.value)
    },
    localFormatted() {
      return {
        remains:
          this.local.remains && this.validRemainSelection(this.local.remains)
            ? this.local.remains
            : null,
      }
    },
  },
}
</script>

<style lang="scss"></style>
